import moment from "moment";
import { MdEmail, MdOutlineTask, MdPhone } from "react-icons/md";
import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { Badge, Card } from "reactstrap";
import Swal from "sweetalert2";
import { keepPreviousData, useQuery } from "@tanstack/react-query";
import qs from "qs";

import {
  handleDeleteUser,
  handleGetUsers,
  handleUpdateUser,
} from "../../services/user.service";

import Header from "../common/Header";
import SearchForm from "./SearchForm";
import UsersTable from "./UsersTable";
import SvgIcons from "../../components/SVGIcons";
import AppTooltip from "../../components/AppTooltip";
import {
  DELETE_CONFIRMATION_ALERT,
  DELETE_DONE_CONFIRMATION,
} from "../../constants/confirmation-const";
import ChallengeModal from "./challangeModal";

export default function UserList() {
  const location = useLocation();
  const params = qs.parse(location.search, { ignoreQueryPrefix: true });
  const [showModalPopup, setShowModalPopup] = useState(false);
  const [selectedUsesr, setSelectedUser] = useState(null);

  const [pagination, setPagination] = React.useState({
    pageIndex: params.page ? parseInt(params.page) - 1 : 0,
    pageSize: params.limit ? parseInt(params.limit) : 10,
  });

  const [sorting, setSorting] = React.useState([]);

  const [filter, setFilter] = React.useState({
    search: "",
    subscription: "",
    status: "",
  });

  const dataQuery = useQuery({
    queryKey: ["data", pagination, sorting, filter],
    queryFn: () => {
      const filterObj = qs.parse(qs.stringify(filter, { skipNulls: true }));
      Object.keys(filterObj).forEach(
        (key) => filterObj[key] === "" && delete filterObj[key]
      );

      if (sorting.length === 0) {
        return handleGetUsers({
          page: pagination.pageIndex + 1,
          limit: pagination.pageSize,
          ...filterObj,
        });
      } else {
        return handleGetUsers({
          page: pagination.pageIndex + 1,
          limit: pagination.pageSize,
          sortBy: sorting
            .map((sort) => `${sort.id}:${sort.desc ? "desc" : "asc"}`)
            .join(","),
          ...filterObj,
        });
      }
    },
    // placeholderData: keepPreviousData
  });

  const handleUserChallengeModel = (userData = null, showModal = false) => {
    if (showModal) {
      setSelectedUser(userData);
    } else {
      setSelectedUser(null);
    }
    setShowModalPopup(showModal);
  };

  //handle last page deletion item
  useEffect(() => {
    if (dataQuery.data?.data?.totalPages < pagination.pageIndex + 1) {
      setPagination({
        pageIndex: dataQuery.data?.data?.totalPages - 1,
        pageSize: 10,
      });
    }
  }, [dataQuery.data?.data?.totalPages]);

  const columns = React.useMemo(
    () => [
      {
        accessorFn: (row) => row.name,
        id: "name",
        header: () => "Name",
      },
      {
        accessorFn: (row) => row.phoneNumber,
        id: "phoneNumber",
        header: () => "Contact",
        cell: (info) => {
          return (
            <div>
              <div className="fs-6 text-black">
                <MdPhone
                  style={{
                    marginRight: "4px",
                  }}
                />
                <a
                  target="_Blank"
                  href={`tel:${info.row.original.countryCode}${info.row.original.phoneNumber}`}
                  className="text-black"
                >
                  {info.row.original.countryCode}{" "}
                  {info.row.original.phoneNumber}
                </a>
              </div>
              {info.row.original.email && (
                <div
                  className="text-truncate text-black"
                  style={{ maxWidth: "150px" }}
                >
                  <MdEmail
                    style={{
                      marginRight: "4px",
                    }}
                  />
                  <a
                    target="_Blank"
                    href={`mailto:${info.row.original.email}`}
                    title={info.row.original.email}
                    className="text-black"
                  >
                    {info.row.original.email}
                  </a>
                </div>
              )}
            </div>
          );
        },
        enableSorting: false,
      },
      {
        accessorFn: (row) => row.age,
        id: "age",
        header: () => "Age",
      },
      {
        accessorFn: (row) => row.steps,
        id: "steps",
        header: () => "Completed Steps",
        enableSorting: false,
        cell: (info) => {
          let color = "primary";
          let textColor = "black";

          if (info.row.original.steps === "Completed") {
            color = "success";
            textColor = "white";
          }

          if (info.row.original.steps === "Just Signed Up") {
            color = "danger";
            textColor = "white";
          }

          return (
            <Badge
              color={color}
              pill
              style={{
                color: textColor,
              }}
            >
              {info.row.original.steps}
            </Badge>
          );
        },
      },
      {
        accessorFn: (row) => row.occupation,
        id: "occupation",
        header: () => "Occupation",
      },
      {
        accessorFn: (row) => row.pouchScore,
        id: "pouchScore",
        header: () => "Pouch Score",
        cell: (info) => {
          return (
            <Link
              className="text-dark"
              to={`/pouch-score/${info.row.original.id}`}
            >
              {info.row.original.pouchScore ? info.row.original.pouchScore : 0}
            </Link>
          );
        },
      },
      {
        accessorFn: (row) => row.subscription,
        id: "subscription",
        header: () => "Subscription",
        enableSorting: false,
      },
      {
        accessorFn: (row) => row.createdAt,
        id: "createdAt",
        header: () => "Created At",
        cell: (info) => {
          return (
            <span>
              {moment(info.row.original.createdAt).format("D/M/YYYY hh:mm A")}
            </span>
          );
        },
      },
      {
        accessorFn: (row) => row.lastActivity,
        id: "lastActivity",
        header: () => "Last Activity",
        cell: (info) => {
          return (
            <span>
              {moment(info.row.original.lastActivity).format(
                "D/M/YYYY hh:mm A"
              )}
            </span>
          );
        },
        enableSorting: false,
      },

      {
        accessorFn: (row) => row.status,
        id: "status",
        header: () => "Status",
        cell: (info) => {
          return (
            <div className="form-check form-switch">
              <input
                className="form-check-input"
                type="checkbox"
                id="flexSwitchCheckDefault"
                onClick={async () => {
                  await handleUpdateUser(info.row.original.id, {
                    status:
                      info.row.original.status === "active"
                        ? "inactive"
                        : "active",
                  }).then(() => {
                    dataQuery.refetch();
                  });
                }}
                checked={info.row.original.status === "active"}
              />
            </div>
          );
        },
      },
      {
        id: "actions",
        isAction: true,
        cell: (info) => {
          return (
            <div className="d-flex items-center gap-2 justify-content-center">
              <AppTooltip title="Challenges" placement="top">
                <Link
                  to="#"
                  onClick={(e) =>
                    handleUserChallengeModel(info.row.original, true)
                  }
                  className="btn btn-link text-secondary lh-1 custom-width-18 custom-height-18 p-0 d-inline-flex align-items-center justify-content-center"
                  aria-label="Edit"
                >
                  <MdOutlineTask
                    fill="rgb(47, 97, 168)"
                    className=" text-secondary"
                    size={24}
                  />
                </Link>
              </AppTooltip>
              <AppTooltip title="Edit" placement="top">
                <Link
                  to={`/users/${info.row.original.id}`}
                  className="btn btn-link text-secondary lh-1 custom-width-18 custom-height-18 p-0 d-inline-flex align-items-center justify-content-center"
                  aria-label="Edit"
                >
                  {SvgIcons.pouchEditIcon}
                </Link>
              </AppTooltip>
              <AppTooltip title="Transaction History" placement="top">
                <Link
                  to={`${info.row.original.id}/transactions?page=1&limit=10`}
                  className="btn btn-link text-secondary lh-1 custom-width-18 custom-height-18 p-0 d-inline-flex align-items-center justify-content-center"
                  aria-label="Edit"
                >
                  {SvgIcons.pouchViewIcon}
                </Link>
              </AppTooltip>
              <AppTooltip title="Delete" placement="top">
                <Link
                  to="#"
                  onClick={(e) => {
                    handleDeleteButton(e, info.row.original);
                  }}
                  className="btn btn-link text-secondary lh-1 custom-width-18 custom-height-18 p-0 d-inline-flex align-items-center justify-content-center"
                  aria-label="Edit"
                >
                  {SvgIcons.pouchDeleteIcon}
                </Link>
              </AppTooltip>
            </div>
          );
        },
        header: () => (
          <div className="d-flex justify-content-center">Actions</div>
        ),
        enableSorting: false,
      },
    ],
    []
  );

  useEffect(() => {
    setPagination({
      pageIndex: 0,
      pageSize: 10,
    });
  }, [filter]);

  const handleDeleteButton = (e, objUser) => {
    //e.preventDefautl();// Prevent the default behavior of the event

    // Display a confirmation alert before deleting
    Swal.fire(DELETE_CONFIRMATION_ALERT).then((result) => {
      const userChoice = result.isConfirmed;
      // Based on the user's choice, either remove the template or cancel the deletion
      switch (userChoice) {
        case true:
          removeUser(objUser.id); // Remove the template if confirmed
          break;
        case false:
          onDeletionCancel(); // Cancel the deletion if not confirmed
          break;
        default:
          onDeletionCancel(); // Cancel the deletion for any other case
          break;
      }
    });
  };

  const removeUser = (userId) => {
    handleDeleteUser(userId).then(() => {
      Swal.fire(DELETE_DONE_CONFIRMATION); // Display deletion confirmation
      dataQuery.refetch(); // Refresh the data
    });
  };

  /**
   * Displays a cancellation confirmation when the deletion action is canceled.
   */
  const onDeletionCancel = () => {
    // Swal.fire(DELETE_CANCEL_CONFIRMATION);// Display cancel confirmation
  };

  return (
    <div className="d-flex flex-column pageContainer p-3 h-100 overflow-auto">
      <Header showAddButton={false} title={"Manage Users"} />
      <div className="flex-grow-1 pageContent position-relative pt-4 overflow-auto">
        <Card className="h-100 bg-white shadow-lg border-0 theme-card-cover">
          <SearchForm filter={filter} setFilter={setFilter} />
          <UsersTable
            columns={columns}
            dataQuery={dataQuery}
            pagination={pagination}
            setPagination={setPagination}
            sorting={sorting}
            setSorting={setSorting}
          />
        </Card>
      </div>

      <ChallengeModal
        showModal={showModalPopup}
        modal={selectedUsesr}
        toggle={handleUserChallengeModel}
        isEditMode={false}
        selectedGoal={{}}
      />
    </div>
  );
}
