import * as Yup from "yup";

const validationSchema = Yup.object({
  title: Yup.string().required("Goal Title is required."),
  amount: Yup.number()
    .positive("Amount must be a positive number.")
    .required("Goal Amount is required."),
});

export { validationSchema };
