import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Card } from "reactstrap";

import { handleGetPouchScore } from "../../services/pouchScore.service";
import Header from "../common/Header";
import SearchForm from "./SearchForm";

export default function PouchScoreCalculation() {
  const [filter, setFilter] = useState({});
  const [pouchScore, setPouchScore] = useState({
    behaviourScore: 0,
    consistencyScore: 0,
    learningScore: 49,
    savingScore: 0,
    bonusPoints: 0,
    pouchScore: 0,
  });

  const params = useParams();
  useEffect(() => {
    handleGetPouchScore({
      userId: params.userId,
    }).then((res) => {
      setPouchScore({ ...res.data.data, learningScore: 49 });
    });
  }, []);

  return (
    <div className="d-flex flex-column pageContainer p-3 h-100 overflow-auto">
      <Header showAddButton={false} title={"Pouch Score"} />
      <div className="flex-grow-1 pageContent position-relative pt-4 overflow-auto">
        <Card className="h-100 bg-white shadow-lg border-0 theme-card-cover">
          <SearchForm filter={filter} setFilter={setFilter} />
          <div className="flex-grow-1 d-flex flex-column px-3 pb-1 overflow-auto">
            <div className="p-1 h-100">
              <ul className="list-group mb-3">
                <li className="list-group-item d-flex justify-content-between lh-sm">
                  <div>
                    <h6 className="my-0">Behaviour Score</h6>
                  </div>
                  <span className="text-muted">
                    {pouchScore.behaviourScore}
                  </span>
                </li>
                <li className="list-group-item d-flex justify-content-between lh-sm">
                  <div>
                    <h6 className="my-0">Consistancy Score</h6>
                  </div>
                  <span className="text-muted">
                    {pouchScore.consistencyScore}
                  </span>
                </li>
                <li className="list-group-item d-flex justify-content-between lh-sm">
                  <div>
                    <h6 className="my-0">Learning Score</h6>
                  </div>
                  <span className="text-muted">{pouchScore.learningScore}</span>
                </li>
                <li className="list-group-item d-flex justify-content-between lh-sm">
                  <div>
                    <h6 className="my-0">Saving Score</h6>
                  </div>
                  <span className="text-muted">{pouchScore.savingScore}</span>
                </li>
                <li className="list-group-item d-flex justify-content-between lh-sm">
                  <div>
                    <h6 className="my-0">Bonus point</h6>
                  </div>
                  <span className="text-muted">{pouchScore.bonusPoints}</span>
                </li>

                <li className="fw-bold list-group-item d-flex justify-content-between">
                  <span>Pouch Score</span>
                  <strong>{pouchScore.pouchScore}</strong>
                </li>
              </ul>
            </div>
          </div>
        </Card>
      </div>
    </div>
  );
}
